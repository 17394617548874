<template>
    <footer class="site-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-12">
                    <p class="copyright-text">Copyright © Africa Money 2024
                        - Design: <a rel="sponsored" href="https://www.nero-technologies.com/" target="_blank">Nero-technologies</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style></style>